import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import './Login.css';
import { useAuth } from "./useAuth";
import db from "./db";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {

  const [bSubmitOn, setSubmitOn] = useState();
  const [bLoading, setLoading] = useState();
  const [nTimerId, setTimerId] = useState();
  const { login } = useAuth();

  const handleChange = ev => {
    clearTimeout(nTimerId);
    setTimerId(setTimeout(() => handleLogin(ev), 200));
    if (bLoading) return;
    setLoading(true);
    setSubmitOn(true);
  };

  const handleLogin = ev => {
    ev.preventDefault();
    const form = ev.target.form;
    const data = new FormData(form);
    const sKeyCode= data.get('keyCode');
    const [sBase64auth, sShifterNameHex] = sKeyCode.split('@');

    // console.log(Array.from(new TextEncoder().encode("Галимова Светлана Валерьевна")).map(i => i.toString(16)).join(''));
    setLoading();
    if (!sShifterNameHex) {
      setSubmitOn(false);
      alert('Ключ не распознан. Проверьте раскладку клавиатуры (EN)');
      return;
    }

    const aShifterNameDec = sShifterNameHex.split(/(\w\w)/g).filter(i => i).map(i => parseInt(i, 16));
    const sShifterName = new TextDecoder().decode(new Uint8Array(aShifterNameDec));
    if (ev.detail) {
      setLoading(true);
      db.set(sBase64auth);
      // db.get('users').then(res => console.log(res.list.find(i => i.name == sShifterName)));
      db.post('_design/main/_view/get-users/queries', {queries: [{keys: ['areas', sShifterName]}]}).then(oRes => {
        const [oAreas, oUser] = oRes.results[0].rows;
        const idArea = oUser.value.area;
        login({ name: oUser.key, id: oUser.value.id, area: oAreas.value[idArea], idArea });
      });
    }
    form.shifterName.value = sShifterName;
    form.keyCode.select();
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className='crpt-logo'
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, color: 'primary.contrastText', bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ m: 2 }}>
            вход в систему
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: 1, maxWidth: 400 }}
          >
            <TextField
              name='shifterName'
              // value={btoa('Буртасов Алексей Викторович')}
              inputProps={{ readOnly: true, placeholder: 'Отсканируйте ключ (код)' }}
              fullWidth
              sx={{ my: 3 }}
            />
            <input
              // type="password"
              autoFocus
              autoComplete="off"
              name='keyCode'
              onChange={handleChange}
              // onSubmit={(ev, x) => console.log(ev, x)}
              onBlur={ev => ev.target.select()}
              style={{ position: 'fixed', zIndex: -1 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ my: 3 }}
              onClick={handleLogin}
              className={bLoading ? 'crpt-submit crpt-load' : 'crpt-submit'}
              disabled={!bSubmitOn}
            >
              {bLoading ? '' : 'войти'}
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}