let sAuth, sLastRev;
const dbUrl = 'https://cdb.orenkontur.ru/ngwater/';
// const dbUrl = 'http://192.168.7.230:5984/ngwater/';

const fetchGet = req =>	fetch(dbUrl + req, {
    credentials: 'include',
    headers: { Authorization: 'Basic ' + sAuth } // shifter:$4!f+er
}).then(res => {
    // console.log();
    sLastRev = res.headers.get('etag');
    // for (let [key, value] of res.headers) console.log(`${key} = ${value}`);
    return res.json();
});

const fetchPut = (sUrl, oBody) => fetch(dbUrl + sUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: 'Basic ' + sAuth,
        'If-Match': sLastRev
    },
    body: oBody ? JSON.stringify(oBody) : undefined
  }).then(res => {
    // console.log(res);
    if (!res.ok) throw(res);
    sLastRev = res.headers.get('etag');
    return res.json();
});

const fetchPost = (sUrl, oBody) => fetch(dbUrl + sUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: 'Basic ' + sAuth
    },
    body: JSON.stringify(oBody)
}).then(response => response.json());

const db = {
    get: (sUrl) => fetchGet(sUrl),
    post: (sUrl, oBody) => fetchPost(sUrl, oBody),
    put: (sUrl, oBody) => fetchPut(sUrl, oBody),
    set: auth => sAuth = auth,
    rev: () => sLastRev
}

export default db;
