import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useAuth } from "./useAuth";
import db from "./db";

export function Codes() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [codesSet, setCodesSet] = useState(new Set());
    const [nTimerId, setTimerId] = useState();

    const hEr = er => db.put('ErS' + Date.now(), er);

    const hChange = ev => {
        clearTimeout(nTimerId);
        setTimerId(setTimeout(() => hScan(ev), 200));
    }

    const hScan = ev => {
        // console.log(ev);
        ev.preventDefault();
        const form = ev.target.form;
        if (!form) return;
        const data = new FormData(form);
        const sScanCode= data.get('scanCode').slice(0, 31);
        form.scanCode.value = '';
        if (sScanCode.slice(0, 2) != '01' || sScanCode.slice(16, 18) != '21') return;
        // form.scanCode.select();
        setCodesSet(prev => new Set(prev.add(sScanCode)));
    }

    const hSend = ev => {
        // return console.log('log' + Date.now());
        db.get('reserved').then(resp => {
            const sArea = 'area' + user.idArea;
            resp.list[sArea] = Array.from(codesSet);
            db.put('reserved', resp).then(res => {
                db.post('', {
                    _id: 'log' + Date.now(),
                    user: user.id,
                    // area: user.idArea,
                    action: 'scan',
                    codes: resp.list[sArea]
                }).catch(hEr);
                navigate("/success", { replace: true })
            }, hEr);
        }, hEr);
    }

    return <Box sx={{ m: 0 }}>
        <Typography sx={{ pt: 2, px: 2 }}>
            <span style={{ color: 'red' }}>Внимание! </span>
            При получении партии с цеха воды необходимо сканировать ВСЕ марки, включая остатки с прошлой партии
        </Typography>
        <Typography sx={{ p: 2 }}>
            Количество отсканированных кодов: {codesSet.size}
            <Button
                variant="contained"
                onClick={() => setCodesSet(new Set())}
                sx={{ m: 2 }}
                disabled={!codesSet.size}
                startIcon={<DeleteIcon />}
            >
                Очистить
            </Button>
            <Button
                variant="contained"
                onClick={hSend}
                sx={{ m: 2 }}
                disabled={!codesSet.size}
                endIcon={<SendIcon />}
            >
                Отправить в цех воды
            </Button>
        </Typography>
        <form onSubmit={hScan} style={{ position: 'fixed', opacity: 0 }}>
            <input
                autoFocus
                autoComplete="off"
                name='scanCode'
                onChange={hChange}
                // onBlur={ev => console.log(ev.target)}
                onBlur={ev => setTimeout(() => ev.target.select(), 10)}
                //
            />
            <input type="submit" />
        </form>
        <List sx={{ flexGrow: 1, maxWidth: 500 }}>
            {Array.from(codesSet).map(sCode => <ListItem
                key={sCode}
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setCodesSet(prev => {
                            prev.delete(sCode);
                            return new Set(prev);
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#fcff25', color: 'black' }} >
                        <QrCode2Icon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={sCode} />
            </ListItem>)}
        </List>
    </Box>
}